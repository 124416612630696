<template>
  <v-card>
    <v-card-title class="justify-center">
      <span> Detalhes </span>

      <v-spacer />

      <v-tooltip
        left
        color="warning"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="isExpand = !isExpand"
          >
            <v-icon
              color="warning"
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
        </template>

        <span>Editar</span>
      </v-tooltip>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col span="12">
          <v-text-field
            v-model="name"
            label="Nome"
            outlined
            readonly
            dense
          />

          <v-text-field
            v-model="company"
            label="Filial"
            readonly
            outlined
            dense
          />

          <v-text-field
            v-model="sector"
            label="Setor"
            readonly
            outlined
            dense
          />
        </v-col>

        <v-col span="12">
          <v-text-field
            v-model="codeSelf"
            label="Cod.Self"
            type="number"
            outlined
            dense
          >
            <template v-slot:append>
              <v-icon>{{ icons.mdiPencil }}</v-icon>
            </template>
          </v-text-field>

          <v-text-field
            v-model="cloudId"
            label="Cloud ID"
            readonly
            outlined
            dense
          />

          <v-text-field
            v-model="occupation"
            label="Função"
            readonly
            outlined
            dense
          />
        </v-col>
      </v-row>

      <v-divider class="mb-8"></v-divider>

      <v-expand-transition>
        <v-card v-show="isExpand">
          <v-form ref="form">
            <v-row>
              <v-col span="12">
                <v-autocomplete
                  v-model="selectedOccupationId"
                  :items="occupationAndSectors"
                  item-value="id"
                  item-text="occupation.nameWithSector"
                  label="Alterar função"
                  class="mt-2"
                  clearable
                  outlined
                  dense
                />

                <v-switch
                  v-model="data.active"
                  label="Ativo?"
                  color="success"
                  class="ml-2 mt-0"
                  inset
                />
              </v-col>

              <v-col span="12">
                <v-autocomplete
                  v-model="selectedCompanyId"
                  :items="companies"
                  item-value="id"
                  item-text="fantasy_name"
                  label="Alterar Filial"
                  class="mt-2"
                  clearable
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-expand-transition>

      <div class="d-flex justify-end mt-5">
        <v-btn
          color="error"
          @click="closeModal()"
        >
          Fechar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="sendModalData()"
        >
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiPencil } from '@mdi/js'
import localStorageSlim from 'localstorage-slim'

export default {
  mixins: [formatters, messages],

  props: {
    data: {
      type: Object,
      required: true,
    },

    occupationAndSectors: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      companies: [],
      selectedCompanyId: '',
      selectedOccupationId: '',

      isExpand: false,
      isLoading: false,

      icons: { mdiPencil },
    }
  },

  computed: {
    name() {
      const name = this.data?.name ?? ''

      return name
    },

    company() {
      const companyName = this.data?.company?.fantasy_name ?? 'Não atribuído'

      return companyName
    },

    codeSelf: {
      get() {
        const code = this.data?.cod_self ?? 'Não atribuído'

        return code
      },

      set(value) {
        this.data.cod_self = Number(value)
      },
    },

    cloudId: {
      get() {
        const cloudId = this.data?.cloud_id ?? 'Não atribuído'

        return cloudId
      },

      set(value) {
        this.data.cloud_id = value
      },
    },

    occupation() {
      const occupation = this.data?.occupation_sector?.occupation?.name ?? 'Não atribuído'

      return occupation
    },

    sector() {
      const sector = this.data?.occupation_sector?.sector?.name ?? 'Não atribuído'

      return sector
    },
  },

  created() {
    this.companies = localStorageSlim.get('listCompanies', { decrypt: true })
  },

  methods: {
    async sendModalData() {
      this.isLoading = true
      const { selectedOccupationId, selectedCompanyId, data } = this

      const body = {
        active: data.active,
        cod_self: data.cod_self,
      }

      if (selectedCompanyId) {
        body.companies_id = selectedCompanyId
      }

      if (selectedOccupationId) {
        body.occupations_sectors_id = selectedOccupationId
      }

      await axiosIns
        .put(`api/v1/records/user/update/${this.data.id}`, body)
        .then(() => {
          this.showMessage({ title: 'Sucesso', text: 'Registro enviado com sucesso', icon: 'success' })
          this.updatedTable()
        })
        .catch(error => this.showErrorMessage(error))
        .finally(() => {
          this.isExpand = false
          this.isLoading = false
          this.closeModal()
          this.resetForm()
        })
    },

    resetForm() {
      const { form } = this.$refs
      form.reset()
    },
  },
}
</script>

<style>
/* Retira o incrementador padrão dos inputs do tipo "number"*/
/*Remove incrementer from numeric type inputs */

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
