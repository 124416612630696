<template>
  <v-card>
    <v-card-title class="mb-4 justify-center">
      Buscar usuário inativo
    </v-card-title>

    <v-card-text>
      <v-form
        ref="form"
        @submit.native.prevent
      >
        <v-text-field
          v-model="cpf"
          label="CPF"
          type="number"
          maxlength="11"
          outlined
          dense
          :rules="[rules.required]"
          @keyup.enter="getUserByCPF()"
        />
      </v-form>

      <v-list
        v-show="showUserDetails"
        subheader
        rounded
        outlined
      >
        <v-subheader>
          <h3>
            Dados do usuário
          </h3>
        </v-subheader>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="d-flex">
              <h4 class="mr-2">
                Nome:
              </h4>
              {{ data.name }}
            </v-list-item-title>

            <v-list-item-title class="d-flex">
              <h4 class="mt-4">
                Situação:
              </h4>
              <v-alert
                class="badge"
                border="left"
                outlined
                dense
                :type="data.active ? 'success': 'error'"
                :color="data.active ? 'success': 'error'"
                @click="activeOrDeactivateUser()"
              >
                <span v-if="!isLoadingActiveUser">
                  {{ userIsActive }}
                </span>
                <v-progress-circular
                  v-else
                  color="white"
                  indeterminate
                  size="25"
                />
              </v-alert>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div class="d-flex justify-end mt-2">
        <v-btn
          color="error"
          @click="closeModal(), resetForm()"
        >
          Fechar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="getUserByCPF()"
        >
          <span v-if="!isLoading">Buscar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'

export default {
  mixins: [formatters, messages],

  data() {
    return {
      cpf: '',
      data: { name: '', company: { fantasy_name: '' } },

      isLoading: false,
      isLoadingActiveUser: false,
      showUserDetails: false,
    }
  },

  computed: {
    userIsActive() {
      const { active } = this.data

      return active ? 'Ativo' : 'Inativo'
    },
  },

  methods: {
    async getUserByCPF() {
      const isValid = this.cpf !== ''
      if (!isValid) return

      this.isLoading = true
      await axiosIns.get(`api/v1/records/user/find_user_cpf/${this.cpf}`)
        .then(response => {
          this.data = response.data.data

          if (this.data.length === 0) {
            this.showMessage({ title: 'Nenhum usuário encontrado', text: 'verifique novamente o cpf digitado' })

            return
          }

          this.showUserDetails = true
        })
        .catch(error => { this.showMessage({ title: 'Erro ao enviar dados', text: error.message, icon: 'error' }) })
        .finally(() => { this.isLoading = false })
    },

    async activeOrDeactivateUser() {
      this.isLoadingActiveUser = true
      const body = { active: !this.data.active }

      await axiosIns.put(`api/v1/records/user/update/${this.data.id}`, body).then(response => {
        this.data.active = response.data.data.active
      }).catch(error => {
        this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' })
      }).finally(() => { this.isLoadingActiveUser = false })
    },

    resetForm() {
      this.$refs.form.reset()
      this.showUserDetails = false
    },
  },
}
</script>

<style lang="scss" scoped>
/* Retira o incrementador padrão dos inputs do tipo "number"*/
/*Remove incrementer from numeric type inputs */
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-size: 14px;
  height: 30px;
  width: 100px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 0;
}

</style>
