import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"mr-4"},[_vm._v(" Usuários ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"info","outlined":""},on:{"click":function($event){return _vm.openModal('filter')}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"25"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterPlusOutline)+" ")]),_vm._v(" Filtro ")],1),_c(VBtn,{staticClass:"ml-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.getUsers()}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterRemoveOutline)+" ")]),_vm._v(" Limpar filtros ")],1),_c(VBtn,{staticClass:"mr-4 ml-4",attrs:{"color":"warning","outlined":""},on:{"click":function($event){return _vm.openModal('history')}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"25"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountRemove)+" ")]),_vm._v(" Usuários inativos ")],1),_c(VBtn,{staticClass:"mr-4",attrs:{"color":"purple","outlined":""},on:{"click":function($event){return _vm.updateUsers()}}},[(!_vm.isLoadingUpdate)?_c('div',[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"25"}},[_vm._v(" "+_vm._s(_vm.icons.mdiUpdate)+" ")]),_c('span',[_vm._v("Atualizar funcionários")])],1):_c(VProgressCircular,{attrs:{"color":"white","indeterminate":""}})],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"loading":_vm.isLoading,"items":_vm.itemsTable,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v("Sem informações para exibir")])]},proxy:true},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"medium":"","color":"warning"},on:{"click":function($event){return _vm.openModal('details', item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")])]}}])}),_c(VDialog,{attrs:{"width":"750px"},model:{value:(_vm.showDetails),callback:function ($$v) {_vm.showDetails=$$v},expression:"showDetails"}},[_c('Details',{key:_vm.userDetails.id,attrs:{"data":_vm.userDetails,"occupation-and-sectors":_vm.occupationAndSectors},on:{"updatedTable":function($event){return _vm.updatedTable()},"close":function($event){_vm.showDetails = false}}})],1),_c(VDialog,{attrs:{"width":"750px"},model:{value:(_vm.showFilter),callback:function ($$v) {_vm.showFilter=$$v},expression:"showFilter"}},[_c('FilterModal',{attrs:{"employees":_vm.users,"list-of-companies":_vm.listOfCompanies,"occupation-and-sectors":_vm.occupationAndSectors},on:{"setDataFromFilterInTable":function (data) { return _vm.setDataFromFilterInTable(data); },"close":function($event){_vm.showFilter = false}}})],1),_c(VDialog,{attrs:{"width":"600px"},model:{value:(_vm.showHistory),callback:function ($$v) {_vm.showHistory=$$v},expression:"showHistory"}},[_c('History',{attrs:{"occupation-and-sectors":_vm.occupationAndSectors},on:{"updatedTable":function($event){return _vm.updatedTable()},"close":function($event){_vm.showHistory = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }