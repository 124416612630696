<template>
  <v-card>
    <v-card-title>
      <span class="mr-4"> Usuários </span>

      <v-spacer />

      <v-btn
        color="info"
        outlined
        @click="openModal('filter')"
      >
        <v-icon
          size="25"
          class="mr-2"
        >
          {{ icons.mdiFilterPlusOutline }}
        </v-icon>
        Filtro
      </v-btn>

      <v-btn
        class="ml-4"
        outlined
        @click="getUsers()"
      >
        <v-icon
          size="30"
          class="mr-2"
        >
          {{ icons.mdiFilterRemoveOutline }}
        </v-icon>
        Limpar filtros
      </v-btn>

      <v-btn
        color="warning"
        class="mr-4 ml-4"
        outlined
        @click="openModal('history')"
      >
        <v-icon
          size="25"
          class="mr-2"
        >
          {{ icons.mdiAccountRemove }}
        </v-icon>
        Usuários inativos
      </v-btn>

      <v-btn
        color="purple"
        class="mr-4"
        outlined
        @click="updateUsers()"
      >
        <div v-if="!isLoadingUpdate">
          <v-icon
            size="25"
            class="mr-2"
          >
            {{ icons.mdiUpdate }}
          </v-icon>
          <span>Atualizar funcionários</span>
        </div>

        <v-progress-circular
          v-else
          color="white"
          indeterminate
        >
        </v-progress-circular>
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :loading="isLoading"
      :items="itemsTable"
      loading-text="Carregando dados..."
    >
      <template v-slot:no-data>
        <span>Sem informações para exibir</span>
      </template>

      <template v-slot:item.edit="{ item }">
        <v-icon
          medium
          color="warning"
          class="me-2"
          @click="openModal('details', item)"
        >
          {{ icons.mdiEye }}
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-model="showDetails"
      width="750px"
    >
      <Details
        :key="userDetails.id"
        :data="userDetails"
        :occupation-and-sectors="occupationAndSectors"
        @updatedTable="updatedTable()"
        @close="showDetails = false"
      ></Details>
    </v-dialog>

    <v-dialog
      v-model="showFilter"
      width="750px"
    >
      <FilterModal
        :employees="users"
        :list-of-companies="listOfCompanies"
        :occupation-and-sectors="occupationAndSectors"
        @setDataFromFilterInTable="(data) => setDataFromFilterInTable(data)"
        @close="showFilter = false"
      ></FilterModal>
    </v-dialog>

    <v-dialog
      v-model="showHistory"
      width="600px"
    >
      <History
        :occupation-and-sectors="occupationAndSectors"
        @updatedTable="updatedTable()"
        @close="showHistory = false"
      ></History>
    </v-dialog>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiAccountRemove,
  mdiEye,
  mdiFilterPlusOutline,
  mdiFilterRemoveOutline, mdiUpdate,
} from '@mdi/js'
import Details from './Details.vue'
import FilterModal from './FilterModal.vue'
import History from './History.vue'

export default {
  components: { History, Details, FilterModal },

  mixins: [formatters, messages],

  data() {
    return {
      headers: [
        { text: 'NOME', value: 'name', align: 'left' },
        { text: 'FILIAL', value: 'company.fantasy_name', align: 'center' },
        { text: 'COD. SELF', value: 'cod_self', align: 'center' },
        { text: 'CLOUD ID', value: 'cloud_id', align: 'center' },
        { text: 'FUNÇÃO', value: 'occupation_sector.occupation.name', align: 'center' },
        { text: 'SETOR', value: 'occupation_sector.sector.name', align: 'center' },
        { text: 'EDITAR', value: 'edit', align: 'center' },
      ],

      users: [],
      itemsTable: [],
      userDetails: {},
      listOfCompanies: [],
      listOfFilteredItems: [],
      occupationAndSectors: [],

      isLoading: false,
      isLoadingUpdate: false,

      showFilter: false,
      showHistory: false,
      showDetails: false,

      icons: {
        mdiEye, mdiUpdate, mdiAccountRemove, mdiFilterRemoveOutline, mdiFilterPlusOutline,
      },
    }
  },

  created() {
    this.getUsers()
    this.getSectors()
    this.getListOfCompanies()
  },

  methods: {
    async getUsers() {
      this.isLoading = true

      await axiosIns
        .get('api/v1/records/user/index')
        .then(response => {
          const { data } = response.data

          this.users = data
          this.itemsTable = data
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => { this.isLoading = false })
    },

    async getSectors() {
      await axiosIns
        .get('api/v1/records/occupation/index')
        .then(response => {
          const { data } = response.data

          data.forEach(({ id, sector, occupation }) => this.occupationAndSectors.push({
            id,
            sector: {
              id: sector.id,
              name: sector.name,
            },
            occupation: {
              id: occupation.id,
              name: occupation.name,
              nameWithSector: `${occupation.name} - ${sector.name}`,
            },
          }))
        })
        .catch(error => this.showErrorMessage(error))
    },

    async getListOfCompanies() {
      await axiosIns.get('api/v1/records/company/index').then(response => {
        this.listOfCompanies = response.data.data
      }).catch(error => this.showErrorMessage(error))
    },

    async updateUsers() {
      this.isLoadingUpdate = true

      await axiosIns
        .get('api/v1/records/user/update_cloud_users')
        .then(response => this.showMessage({
          title: 'Dados atualizados com sucesso',
          text: response.data.data[0],
          icon: 'success',
          timer: 5000,
        }))
        .catch(error => this.showErrorMessage(error))
        .finally(() => {
          this.isLoadingUpdate = false
          this.updatedTable()
        })
    },

    openModal(type, data = {}) {
      const modal = {
        history: () => {
          this.showHistory = true
        },
        filter: () => {
          this.showFilter = true
        },
        details: () => {
          this.showDetails = true
          this.userDetails = data
        },
      }

      if (type in modal) {
        modal[type]()

        return
      }

      console.error('Modal type does not exist in openModal function')
    },

    setDataFromFilterInTable(data) {
      this.itemsTable = data
    },

    updatedTable() {
      this.getUsers()
    },
  },
}
</script>
